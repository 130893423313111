<template>
  <div class="order-summary">
    <ul class="order-summary__list">
      <!--      <li v-for="(item, i) in pricelistOther" :key="i" class="order-summary__item">-->
      <li v-for="(item, i) in pricelistOther" :key="i" class="order-summary__item">
        <div class="order-summary__label standart-title">
          <!--          {{ getStringWithoutPrice(item.label) }}-->
          {{ item.name }}
          <!--          {{ item }}-->
        </div>
        <div class="order-summary__value standart-text">
          <!--          {{ getStringWithoutPrice(item.value) }}-->
          {{ getVal(item.count) }}
        </div>
        <div class="order-summary__price standart-text">
          <!--          {{ getStringWithoutPrice(item.value) }}-->
          {{ (item.price * item.count).toFixed(2) }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { isNaN } from 'lodash';
export default {
  name: 'OrderStackSummary',
  props: {
    details: {
      type: Object,
      default: () => {},
      // required: true,
      // validator: val => val,
    },
  },
  computed: {
    // pricelistMain() {
    //   return {
    //     label: this.$t('pricelist'),
    //     value: this.details.main.desc,
    //   };
    // },
    // pricelistAddons() {
    //   if (!this.details.addon) return []; // аренда физических серверов - прилетает без аддона
    //   return this.details.addon.map(addon => ({
    //     label: addon.desc,
    //     value: this.getValueAsLocalString(addon),
    //   }));
    // },
    pricelistOther() {
      return Object.values(this.details);
      // return Object.keys(this.details).forEach(addon => {
      //   list.push({
      //     label: this.details[addon].name,
      //     value: this.details[addon].count,
      //     price: this.details[addon].price,
      //   });
      // });

      // const list = [];
      // return this.details
      // console.log(this.details);
      // if (Object.keys(this.details).length > 0) {
      //   Object.keys(this.details).forEach(addon => {
      //     list.push({
      //       label: this.details[addon].name,
      //       value: this.details[addon].count,
      //       price: this.details[addon].price,
      //     });
      //   });
      //   return list;
      // } else return [];
      // : // .filter(x => x.label !== 'Адрес Plesk Сервера')
      // [];
    },
    list() {
      return [...this.pricelistOther];
    },
  },
  watch: {
    // watch: {
    pricelistOther: {
      handler: function (event) {
        // console.log(event);
        // this.GetProducts();
      },
      immediate: true,
    },
    // },
  },
  methods: {
    getVal(val) {
      return val;
    },
    // getValueAsLocalString(addon) {
    //   if (addon.enumerationitem_name) return addon.enumerationitem_name;
    //   const value = addon.value;
    //   if (['on', 'off'].includes(value)) return this.$t(`checkbox.${value}`);
    //   else if (!isNaN(parseInt(value))) {
    //     const measure = addon.measure ? ` ${addon.measure}` : '';
    //     let str = value + measure;
    //     if (addon.addonvalue && parseInt(addon.addonvalue)) {
    //       str += ` (${this.$t('additional')} ${addon.addonvalue + measure})`;
    //     }
    //     return str;
    //   } else return value || '-';
    // },
    // getStringWithoutPrice(str) {
    //   const regex = /\(.*\d*₽.*\)/gm;
    //   if (str.search(regex)) return str.replace(regex, '').trim();
    //   else return str;
    // },
  },
};
</script>

<i18n>
{
  "ru": {
    "pricelist": "Тариф",
    "additional": "из них дополнительно",
    "checkbox": {
      "on": "Вкл",
      "off": "Выкл"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.order-summary {
  &__list {
    no-style-list();
  }
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, baseline);
    }
    & + & {
      //margin-top: 1.25rem;
      +breakpoint(sm-and-up) {
        margin-top: 0.25rem;
      }
    }
  }
  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 33%;
      margin-right: 1.5rem;
    }
  }
  &__value {
    +breakpoint(sm-and-up) {
      flex: 0 0 11%;
      margin-right: 1.5rem;
    }
  }
  &__price {
    +breakpoint(sm-and-up) {
      flex: 0 0 11%;
      margin-right: 1.5rem;
    }
  }
}
</style>
